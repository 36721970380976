import React, { useEffect, useState } from "react";
import { useResponsiveSlide } from "../hooks/slidesResizer";
import ServicesService from "./services/servicesService";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCoverflow,
} from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/components/effect-coverflow/effect-coverflow.scss";
import TtitleUnderscored from "../common/TitleUnderscored";
import { useTranslation } from "react-i18next";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectCoverflow]);

const ServiceSection = (props) => {
  const slidesPerView = useResponsiveSlide();
  const { t, i18n } = useTranslation("common");

  const [services, setServices] = useState([]);

  useEffect(() => {
    async function fetchServices() {
      const servicesService = new ServicesService();
      const returnedServices = await servicesService.getAll();
      setServices(returnedServices);
    }
    fetchServices();
  }, [i18n.language]);

  return (
    <section id={props.id}>
      <div>
        <TtitleUnderscored
          text={t("services")}
          width={"200px"}
          className="mb-20"
        ></TtitleUnderscored>
        <Swiper
          className="mt-10"
          slidesPerView={slidesPerView}
          spaceBetween={50}
          navigation={slidesPerView > 1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          loop={true}
          pagination={{ dynamicBullets: true }}
          style={{
            paddingBottom: "3rem",
          }}
        >
          {services.map((service) => (
            <SwiperSlide
              className="text-white bg-transparent"
              key={service.title}
            >
              <div className="bg-black bg-opacity-50 w-80 h-80 mx-auto text-center p-7 rounded-xl">
                <img
                  alt="none"
                  src={service.url}
                  className="max-w-20 max-h-20 mx-auto"
                ></img>
                <h2 className="text-2xl">{service.title}</h2>
                <div
                  className="bg-white w-16 mx-auto my-1"
                  style={{ height: "2px" }}
                ></div>
                <p className="text-base">{service.description}</p>
              </div>
            </SwiperSlide>
          ))}
          {/* <SwiperSlide className="text-white bg-transparent">
            <div className="bg-black bg-opacity-50 w-80 h-80 mx-auto text-center p-7 rounded-xl">
              <FontAwesomeIcon
                icon={faVideo}
                className="text-7xl"
              ></FontAwesomeIcon>
              <h2 className="text-2xl">Title</h2>
              <div
                className="bg-white w-16 mx-auto my-1"
                style={{ height: "2px" }}
              ></div>
              <p className="text-base">Descrition</p>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </section>
  );
};

export default ServiceSection;
