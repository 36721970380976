const availableLanguages = [
  {
    code: "en",
    description: "English",
  },
  {
    code: "es",
    description: "Spanish",
  },
  {
    code: "gl",
    description: "Galcian",
  },
];

export default availableLanguages;
