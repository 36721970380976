import BaseService from "../../BaseService";

export default class PhotoService extends BaseService {
  async getAll() {
    const queryParameters = {
      populate: "content",
      showcaseInWebsite: true,
    };
    const { data } = await this.get("photos", queryParameters);
    if (!data) return [];

    return data.map((sn) => {
      const { attributes: photo } = sn;
      let photoData = { url: "" };
      if (photo.content.data.attributes.formats) {
        photoData =
          photo.content.data.attributes.formats.small ||
          photo.content.data.attributes.formats.thumbnail;
      }
      return {
        name: photo.title,
        imageUrl:
          photoData.url === ""
            ? photoData.url
            : this.getAbsoluteUrlForUpload(photoData.url),
      };
    });
  }
}
