import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import en from "./locales/en.json";
import es from "./locales/es.json";
import gl from "./locales/gl.json";
import languages_en from "./locales/languages_en.json";
import languages_es from "./locales/languages_es.json";
import languages_gl from "./locales/languages_gl.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    resources: {
      en: {
        common: en,
        languages: languages_en,
      },
      es: {
        common: es,
        languages: languages_es,
      },
      gl: {
        common: gl,
        languages: languages_gl,
      },
    },
  });

export default i18n;
