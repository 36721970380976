import BaseService from "../../BaseService";

export default class BrandService extends BaseService {
  async getAll() {
    const queryParameters = {
      populate: "image",
    };

    const { data } = await this.get("brands", queryParameters);

    if (!data) return [];

    return data.map((brand) => {
      const { attributes: brandData } = brand;
      return {
        name: brandData.title,
        url: this.getAbsoluteUrlForUpload(
          brandData.image.data.attributes.formats.small.url
        ),
      };
    });
  }
}
