import React, { useEffect, Suspense, useState } from "react";
import Background from "./common/Background/Background";
import Header from "./common/Header";
import VideoSection from "./VideoSection/VideoSection";
import PhotoSection from "./PhotoSection/PhotoSection";
import ServiceSection from "./ServiceSection/ServiceSection";
import BrandSection from "./BrandSection/BrandSection";
import ContactSection from "./ContactSection/ContactSection";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import SocialNetworks from "./SocialNetworks/SocialNetworks";
import { useTranslation } from "react-i18next";
import ConfigService from "./ConfigSection/configService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeEurope } from "@fortawesome/free-solid-svg-icons";
import availableLanguages from "./config/localesAvailable";

function App() {
  const { t, i18n } = useTranslation("common");
  const { t: tLanguages } = useTranslation("languages");
  const [description, setDescription] = useState();
  const [backgroundUrl, setBackgroundUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(() => {
    async function setData() {
      const configService = new ConfigService();
      const configurableImages = await configService.getConfigurableImages();
      setBackgroundUrl(configurableImages.backgroundUrl);
      setLogoUrl(configurableImages.logoUrl);
    }

    setData();
  }, []);

  useEffect(() => {
    async function setData() {
      const configService = new ConfigService();
      const description = await configService.getDescription();
      setDescription(description);
    }

    setData();
  }, [i18n.language]);

  return (
    <React.Fragment>
      <Background url={backgroundUrl} />
      <Suspense fallback="loading">
        <Navbar bg="dark" variant="dark" expand="sm" className="normal-nav">
          <Navbar.Toggle></Navbar.Toggle>
          <Container>
            <Navbar.Collapse>
              <Nav className="me-auto mx-auto text-center xl:text-1xl">
                <Nav.Link
                  href="/portfolio"
                  target="_blank"
                  className="link-item"
                >
                  {t("portfolio")}
                </Nav.Link>
                <Nav.Link href="#video-section" className="link-item">
                  {t("videos")}
                </Nav.Link>
                <Nav.Link href="#service-section" className="link-item">
                  {t("services")}
                </Nav.Link>
                <Nav.Link href="#contact-section" className="link-item">
                  {t("contact")}
                </Nav.Link>
                <NavDropdown
                  className="link-item"
                  onSelect={(languageSelected) =>
                    i18n.changeLanguage(languageSelected)
                  }
                  title={
                    <FontAwesomeIcon icon={faGlobeEurope}></FontAwesomeIcon>
                  }
                >
                  {availableLanguages.map((language) => {
                    return (
                      <NavDropdown.Item
                        eventKey={language.code}
                        key={language.code}
                      >
                        {tLanguages(language.code)}
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Header
          id="header"
          logoUrl={logoUrl}
          description={description}
        ></Header>
        <VideoSection id="video-section"></VideoSection>
        <PhotoSection id="photo-section"></PhotoSection>
        <ServiceSection id="service-section"></ServiceSection>
        <BrandSection></BrandSection>
        <ContactSection id="contact-section"></ContactSection>
        <SocialNetworks></SocialNetworks>
      </Suspense>
    </React.Fragment>
  );
}

export default App;
