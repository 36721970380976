import BaseService from "../BaseService";

export default class ConfigService extends BaseService {
  async getConfigurations() {
    const populates = {
      populate: "logo,background",
    };
    const { data } = await this.get("configuration", populates, true);

    const backgroundRelativePath =
      data.attributes.background.data.attributes.url;
    const logoRelativePath = data.attributes.logo.data.attributes.url;

    return {
      description: data.attributes.introDescription,
      backgroundUrl: backgroundRelativePath
        ? this.getAbsoluteUrlForUpload(backgroundRelativePath)
        : "",
      logoUrl: logoRelativePath
        ? this.getAbsoluteUrlForUpload(logoRelativePath)
        : "",
    };
  }

  async getConfigurableImages() {
    const populates = {
      populate: ["logo", "background"],
    };
    const { data } = await this.get("configuration", populates, true);

    const backgroundRelativePath =
      data.attributes.background.data.attributes.url;
    const logoRelativePath = data.attributes.logo.data.attributes.url;

    return {
      backgroundUrl: backgroundRelativePath
        ? this.getAbsoluteUrlForUpload(backgroundRelativePath)
        : "",
      logoUrl: logoRelativePath
        ? this.getAbsoluteUrlForUpload(logoRelativePath)
        : "",
    };
  }

  async getDescription() {
    const { data } = await this.get("configuration", {}, true);

    return data.attributes.introDescription;
  }
}
