import React, { Component } from "react";

class TextBlock extends Component {
  render() {
    const { text } = this.props;
    return (
      <div className="w-4/5 m-auto md:w-3/5 lg:w-2/5">
        <p className="text-gray-300 text-justify text-base 2xl:text-2xl">
          {text}
        </p>
      </div>
    );
  }
}

export default TextBlock;
