import BaseService from "../../BaseService";

export default class ContactService extends BaseService {
  async sendContactMessage(message) {
    const bodyToSend = {
      data: message,
    };

    const { data } = await this.post("contact-messages", bodyToSend);

    return data !== undefined && data !== null;
  }

  async getSubjects() {
    const { data } = await this.get("contact-subjects", {}, true);

    return data.map((subject) => {
      return {
        id: subject.id,
        text: subject.attributes.optionText,
      };
    });
  }
}
