import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TtitleUnderscored from "../common/TitleUnderscored";
import validationService from "../utils/validationService";
import ContactService from "./services/contactService";
import "./ContactSection.css";
const ContactSection = (props) => {
  const { t, i18n } = useTranslation("common");

  const PLACEHOLDER_SUBJECT_OPTION = "reasonPlaceholder";

  //TODO: Localize this error message

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState(PLACEHOLDER_SUBJECT_OPTION);
  const [message, setMessage] = useState("");

  const [subjectOptions, setSubjectOptions] = useState([]);

  const [showSuccessStatus, setShowSuccessStatus] = useState(false);
  const [showErrorStatus, setShowErrorStatus] = useState(false);

  const [incorrectMessageDisplay, setIncorrectMessageDisplay] = useState({
    nameError: false,
    emailError: false,
    subjectError: false,
    messageError: false,
  });

  useEffect(() => {
    async function fetchOptions() {
      const contactService = new ContactService();
      const options = await contactService.getSubjects();
      setSubjectOptions(options);
    }
    fetchOptions();
  }, [i18n.language]);

  async function handleFormSubmit(event) {
    event.preventDefault();
    const errors = {};
    if (validationService.isEmpty(name)) {
      errors.nameError = true;
    }
    if (!validationService.isValidEmail(email)) {
      errors.emailError = true;
    }
    if (
      validationService.isEmpty(subject) ||
      message.subject === PLACEHOLDER_SUBJECT_OPTION
    ) {
      errors.subjectError = true;
    }
    if (validationService.isEmpty(message)) {
      errors.messageError = true;
    }

    if (Object.keys(errors).length > 0) {
      setIncorrectMessageDisplay(errors);
      return false;
    }

    const selectedSubject = subjectOptions.find(
      (option) => option.id === parseInt(subject)
    );

    const contactService = new ContactService();
    const success = await contactService.sendContactMessage({
      name,
      email,
      subject: selectedSubject.text,
      message,
    });

    if (success) {
      setShowSuccessStatus(true);
      setShowErrorStatus(false);
    } else {
      setShowErrorStatus(true);
      setShowSuccessStatus(false);
    }
  }

  return (
    <section id={props.id}>
      <TtitleUnderscored
        text={t("contact")}
        width={"200px"}
        className="mb-20"
      ></TtitleUnderscored>
      <form onSubmit={handleFormSubmit} className="p-8 sm:p-0 mx-auto sm:w-2/3">
        <input
          placeholder={t("contactform.name")}
          name="name"
          value={name}
          type="text"
          onChange={(event) => setName(event.target.value)}
        />
        {incorrectMessageDisplay.nameError && (
          <p className="text-red-400">{t("contactform.errors.name")}</p>
        )}
        <input
          placeholder={t("contactform.email")}
          name="email"
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        {incorrectMessageDisplay.emailError && (
          <p className="text-red-400">{t("contactform.errors.email")}</p>
        )}
        <select
          placeholder="Reason"
          name="reason"
          value={subject}
          onChange={(event) => setSubject(event.target.value)}
        >
          <option
            value={PLACEHOLDER_SUBJECT_OPTION}
            disabled
            className="text-gray-400"
          >
            {t("contactform.subject")}
          </option>
          {subjectOptions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.text}
            </option>
          ))}
          <option value="other">{t("contactform.otherOption")}</option>
        </select>
        {incorrectMessageDisplay.messageError && (
          <p className="text-red-400">{t("contactform.errors.subject")}</p>
        )}
        <textarea
          placeholder={t("contactform.message")}
          name="message"
          value={message}
          rows="7"
          onChange={(event) => setMessage(event.target.value)}
        ></textarea>
        {incorrectMessageDisplay.messageError && (
          <p className="text-red-400">{t("contactform.errors.message")}</p>
        )}
        <input type="submit" value={t("contactform.send")} />
        {showSuccessStatus && (
          <p className="text-green-400">
            {t("contactform.sendSuccessMessage")}
          </p>
        )}
        {showErrorStatus && (
          <p className="text-red-400">{t("contactform.sendFailedMessage")}</p>
        )}
      </form>
    </section>
  );
};

export default ContactSection;
