import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCoverflow,
} from "swiper";
import React, { useState, useEffect } from "react";
import BrandService from "./services/brandService";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "swiper/components/effect-coverflow/effect-coverflow.scss";
import TtitleUnderscored from "../common/TitleUnderscored";
import { useTranslation } from "react-i18next";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectCoverflow]);

const BrandSection = (props) => {
  const { t } = useTranslation("common");

  const [brands, setBrands] = useState([]);

  useEffect(() => {
    async function fetchBrands() {
      const brandService = new BrandService();
      const fetchedBrands = await brandService.getAll();
      if (fetchedBrands && fetchedBrands.length > 0) {
        setBrands(fetchedBrands);
      }
    }
    fetchBrands();
  }, []);

  return (
    <section>
      <TtitleUnderscored
        text={t("brands")}
        width={"900px"}
        className="mb-20 px-10"
      ></TtitleUnderscored>
      <div className="flex flex-wrap justify-center gap-14">
        {brands.map((brand) => (
          <div className="w-56">
            <img src={brand.url} alt={brand.name} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default BrandSection;
