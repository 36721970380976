const TtitleUnderscored = ({ text, width, className }) => {
  return (
    <div className={className}>
      <h1 className="title">{text}</h1>
      <div
        className={`bg-gray-400 bg-opacity-20 mx-auto mt-2`}
        style={{ height: "2px", maxWidth: width }}
      ></div>
    </div>
  );
};

export default TtitleUnderscored;
