import React, { useEffect, useState } from "react";
import "./VideoSection.css";
import VideoService from "./services/videoService";
import TtitleUnderscored from "../common/TitleUnderscored";
import { useTranslation } from "react-i18next";

const VideoSection = (props) => {
  const [videos, setVideos] = useState([]);
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    async function fetch() {
      const videoService = new VideoService();
      const videos = await videoService.getAll();
      setVideos(videos);
    }
    fetch();
  }, [i18n.language]);

  return (
    <section
      id={props.id}
      className="min-h-screen flex bg-gradient-to-b from-transparent to-black"
    >
      <div className="flex-grow self-center mb-10">
        <div className="mb-5">
          <TtitleUnderscored
            text={t("videos")}
            width={"200px"}
            className="mb-20"
          ></TtitleUnderscored>
        </div>
        <div className="grid lg:grid-rows-2 lg:grid-cols-3 sm:grid-rows-1 sm:grid-cols-1 grid-flow-row lg:mx-20 justify-center gap-20">
          {videos.map((video) => (
            <iframe
              key={video}
              className="mx-auto mh-64 border-2 border-opacity-10"
              title="test"
              src={video}
              allowFullScreen
            ></iframe>
          ))}
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
