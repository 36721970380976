import React, { useState, useEffect } from "react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCoverflow,
} from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import PhotoService from "./services/photoService";
import { useResponsiveSlide } from "../hooks/slidesResizer";
// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import TtitleUnderscored from "../common/TitleUnderscored";
import { useTranslation } from "react-i18next";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectCoverflow]);

const PhotoSection = (props) => {
  const [photos, setPhotos] = useState([]);

  const slidesPerView = useResponsiveSlide();

  useEffect(() => {
    (async () => {
      const photoService = new PhotoService();
      const photos = await photoService.getAll();
      setPhotos(photos);
    })();
  }, []);

  const { t } = useTranslation("common");

  return (
    <section id={props.id}>
      <div>
        <TtitleUnderscored
          text={t("photos")}
          width={"200px"}
          className="mb-20"
        ></TtitleUnderscored>
        <Swiper
          effect="coverflow"
          spaceBetween={50}
          slidesPerView={slidesPerView}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          loop={true}
          navigation={slidesPerView > 1}
          pagination={{ dynamicBullets: true }}
          style={{
            paddingBottom: "3rem",
          }}
        >
          {photos.map((photo) => (
            <SwiperSlide className="text-white my-auto" key={photo.imageUrl}>
              <img src={photo.imageUrl} alt="" className="mx-auto my-auto" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default PhotoSection;
