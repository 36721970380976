import BaseService from "../../BaseService";

export default class ServicesService extends BaseService {
  async getAll() {
    const queryParameters = {
      populate: "icon",
    };

    const { data } = await this.get("services", queryParameters, true);
    return data.map((service) => {
      if (!service) {
        return {};
      }

      return {
        title: service.attributes.title,
        description: service.attributes.description,
        url: service.attributes.icon.data
          ? this.getAbsoluteUrlForUpload(
              service.attributes.icon.data.attributes.formats.small.url
            )
          : "",
      };
    });
  }
}
