const validationService = {
  isValidEmail(email) {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    return !this.isEmpty(email) && emailRegex.test(email);
  },
  isEmpty(value) {
    return !value || !value.trim();
  },
};

export default validationService;
