import Logo from "./Logo";
import React from "react";
import TextBlock from "./TextBlock";

const Header = ({ logoUrl, description, id }) => {
  return (
    <div id={id} className="section">
      <div className="w-screen">
        <Logo url={logoUrl}></Logo>
        <TextBlock text={description}></TextBlock>
      </div>
    </div>
  );
};

export default Header;
