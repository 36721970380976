import { useState, useEffect } from "react";

function useResponsiveSlide() {
  const SM_SIZE = 950;
  const [slidesPerView, setSlidesPerView] = useState(
    window.innerWidth <= SM_SIZE ? 1 : 3
  );

  useEffect(() => {
    function handleResize() {
      setSlidesPerView(window.innerWidth <= SM_SIZE ? 1 : 3);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  return slidesPerView;
}

export { useResponsiveSlide };
