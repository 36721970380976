import styles from "./Background.module.css";
import React from "react";

const Background = ({ url }) => {
  const style = {
    backgroundImage: `url(${url})`,
  };
  return (
    <div className="h-0">
      <div className={styles.background} style={style}></div>;
      <div className={styles.foreground}></div>
    </div>
  );
};

export default Background;
