import SocialNetworkService from "./services/socialNetworkService";
import React, { useState, useEffect } from "react";

const SocialNetworks = (props) => {
  const [socialNetworks, setSocialNetworks] = useState([]);

  useEffect(() => {
    async function fetchSocialNetworks() {
      const socialNetworkService = new SocialNetworkService();
      const snetworks = await socialNetworkService.getAll();
      if (snetworks && snetworks.length > 0) {
        setSocialNetworks(snetworks);
      }
    }
    fetchSocialNetworks();
  }, []);

  return (
    <div className="p-4">
      <div className="mx-auto" style={{ width: "fit-content" }}>
        {socialNetworks.map((sn) => (
          <a href={sn.link} target="_blank" rel="noreferrer" key={sn.name}>
            <img src={sn.imageUrl} alt={sn.name} className="w-10"></img>
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialNetworks;
