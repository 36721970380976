import BaseService from "../../BaseService";

export default class SocialNetworkService extends BaseService {
  async getAll() {
    const queryParameters = {
      populate: "image",
    };
    const { data } = await this.get("social-networks", queryParameters);
    if (!data) return [];

    return data.map((sn) => {
      const { attributes: socialNetworkData } = sn;
      return {
        name: socialNetworkData.title,
        imageUrl: socialNetworkData.image.data
          ? this.getAbsoluteUrlForUpload(
              socialNetworkData.image.data.attributes.formats.small.url
            )
          : "",
        link: socialNetworkData.link,
      };
    });
  }
}
