//This dependency should come from constructor
import i18n from "./i18n";

export default class BaseService {
  constructor() {
    this.baseConfiguration = {
      url: process.env.REACT_APP_BACKEND_URL,
      api_prefix: process.env.REACT_APP_API_PREFIX,
      headers: {
        Authorization: process.env.REACT_APP_API_TOKEN,
        Accept: "application/json,*/*",
      },
    };
  }

  getUrl(relativePath, queryParameters, localized = false) {
    const internalQueryParameters = {
      ...queryParameters,
    };

    if (localized) {
      const primaryLanguage = i18n.language.split("-")[0];
      internalQueryParameters.locale = primaryLanguage;
    }

    const parameters = this.getQueryParametersAsString(internalQueryParameters);
    const path = relativePath.startsWith("/")
      ? relativePath.substr(1)
      : relativePath;
    return `${this.baseConfiguration.url}/${this.baseConfiguration.api_prefix}/${path}${parameters}`;
  }

  async get(relativePath, queryParameters, localized = false) {
    const data = await fetch(
      this.getUrl(relativePath, queryParameters, localized),
      {
        method: "GET",
        headers: this.baseConfiguration.headers,
      }
    );

    const parsedData = await data.json();
    return parsedData;
  }

  async post(relativePath, body) {
    const data = await fetch(this.getUrl(relativePath, {}), {
      method: "POST",
      headers: {
        ...this.baseConfiguration.headers,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    return data.json();
  }

  getQueryParametersAsString(queryParameters) {
    if (!queryParameters) {
      return "";
    }
    const stringQueryParameters = Object.keys(queryParameters).reduce(
      (previous, current, index) => {
        const isFirst = index === 0;
        const isArray = Array.isArray(queryParameters[current]);

        const connectionCharacter = isFirst ? "" : "&";

        let queryParametersEncoded = `${current}=${queryParameters[current]}`;

        if (isArray && queryParameters[current].length > 0) {
          const queryParametersJoined = queryParameters[current].join(
            `&${current}=`
          );
          queryParametersEncoded = `${current}=${queryParametersJoined}`;
        }

        return `${previous}${connectionCharacter}${queryParametersEncoded}`;
      },
      "?"
    );

    return stringQueryParameters;
  }

  getAbsoluteUrlForUpload(relativePath) {
    const path = relativePath.startsWith("/")
      ? relativePath.substr(1)
      : relativePath;
    return `${this.baseConfiguration.url}/${path}`;
  }
}
